@font-face {
  font-family: "sans-bold";
  src: url(../fonts/OpenSans-Bold.woff) format("woff");
}
@font-face {
  font-family: "sans-light";
  src: url(../fonts/OpenSans-Light.woff) format("woff");
}
@font-face {
  font-family: "sans-regular";
  src: url(../fonts/OpenSans-Regular.woff) format("woff");
}
@font-face {
  font-family: "sans-medium";
  src: url(../fonts/OpenSans-Medium.woff) format("woff");
}
@font-face {
  font-family: "sans-bold-italic";
  src: url(../fonts/OpenSans-BoldItalic.woff) format("woff");
}

.wrapper {
  width: 80%;
  margin: 0 auto;
}
html {
  scroll-behavior: smooth;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
  margin: 0;
}
/* image slider */

.slide {
  height: 100px;
  width: 100px;
  display: grid;
  place-items: center;
  padding: 15px;
  perspective: 100px;
}
.global-title {
  color: #1e3f20;
  font-family: "sans-medium";
  margin-top: 0;
  text-transform: capitalize;
  font-size: 40px;
}
img.img-sl {
  height: 100%;
  width: 100%;
  transition: transform 1s;
}
.image-slider {
  display: grid;
  display: none;
  place-items: center;
  margin-top: 30px;
  position: relative;

  overflow: hidden;
  height: 250px;
  width: 100%;
}
.image-slider-track {
  display: flex;
  width: calc(200px * 12);
  animation: play 40s linear infinite;
}

@keyframes play {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-250px * 6));
  }
}

.image-slider::before,
.image-slider::after {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  content: "";
  height: 100%;
  width: 15%;
  z-index: 2;
  position: absolute;
}

.image-slider::before {
  left: 0;
  top: 0;
}

.image-slider::after {
  right: 0;
  top: 0;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

img.img-sl:hover {
  transform: translateZ(20px);
}
@media all and (max-width: 480px) {
  .image-slider {
    display: block;
  }
}
